import DashboardLayout from "@/pages/layouts/DashboardLayout";

let jobRequestRoutes = {
  path: '/Job-requests',
  component: DashboardLayout,
  redirect: '/Job-requests/list',
  children: [
    {
      path: 'list',
      name: 'Job Request List',
      component:()=>import(/* webpackChunkName: "Job-requests" */ '@/pages/jobRequests/jobRequests/JobRequestList'),
      meta: {
        permissionsCodes: ['Job-requests/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Job Request Details',
      component:()=>import(/* webpackChunkName: "Job-requests" */ '@/pages/jobRequests/jobRequests/JobRequestView'),
      meta: {
        permissionsCodes: ['Job-requests/get'],
      }
    },
  ]
}

export default jobRequestRoutes;
